<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageResize', ImageResize)

/**
 * Contents component
 */
export default {
  page: {
    title: 'Near By Local Approve',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueEditor,
    Loading,
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
      isLoading: false,
      baseImageUrl: BASE_IMAGE_URL,
      
      form: {
        status: true,
        title: '',
        address: '',
        price : '',
        open :  '',
        close :  '',
        summary :  '',
        latitude : '',
        longitude : '',
        image: [],
 
      },
      selectedPlace: '',
      optionsPlace : '',

      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 5,
        parallelUploads: 50,
        paramName: 'images',
        uploadMultiple: true,
        acceptedFiles: 'image/*',      
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      ismanuallyadd: false,
      isEdit: false,
    }
  },
  validations: {
    form: {
      title: {
        required,
      }
    },
  },
  mounted() {
    this.initialUpdateItem()
  },
  methods: {
    onTimeOpen(ctx) {
      console.log("o" + ctx)
        this.open = ctx
    },
    onTimeclose(ctx) {
      console.log("c" + ctx)

        this.close = ctx
    },
    doAjax() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },

 /*    vfileAdded(file) {
      let result = file.map((e => { return e }))
      this.form.image.push(result)

      console.log("log img" + this.form.image)

     // this.form.image = file
      this.ismanuallyadd = false
    }, */
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
 

    async formSubmit() {
        if (!this.form.image) {
          this.$toast.error('Image field must not be empty ')
        }

      this.doAjax()
      
      let body = {
        title : this.form.title,
        address : this.form.address,
        price : this.form.price,
        open : this.form.open,
        close : this.form.close,      
        summary : this.form.summary,
        latitude : this.form.latitude,
        longitude : this.form.longitude,
        gym_id : []
      }
 
      const data = new FormData()
      if (!this.ismanuallyadd) {
        for (const i of Object.keys(this.form.image)) {
          data.append('image', this.form.image[i])
        }
      }
      
     
      data.append('data', JSON.stringify(body))
        
      const request = axios.post('/api/nearapprove', data)
      await request.then(async (result) => {
          this.$toast.success('Saved successfully')
          this.$router.back()
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },


    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('image', file)
      await axios.post('api/content/ckupload_images',formData,{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((result) => {
          let url = result.data.url // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Near By Local Approve</h4>
      </div>

      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        v-model="$v.form.title.$model"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        :state="validateState('title')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>  
                    <b-form-group label-cols-lg="0">
                      <label>Address</label>
                      <b-form-input
                        id="address"
                        v-model="form.address"
                        placeholder="Enter address"
                        name="address"
                        type="text"
                      ></b-form-input>                  
                    </b-form-group>      

                    <b-form-group label-cols-lg="0">
                      <label>price</label>
                      <b-form-input
                        id="price"
                        v-model="form.price"
                        placeholder="Enter price "
                        name="price"
                        type="number"
                        step="any"
                      ></b-form-input>                  
                    </b-form-group>

                    <b-row>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Open:</label>
                        <b-form-timepicker v-model="form.open" locale="en"></b-form-timepicker>
                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Close:</label>
                        <b-form-timepicker v-model="form.close" locale="en"></b-form-timepicker>
                      </b-col>
                    </b-row>


                    <b-form-group label-cols-lg="0">
                      <label>Summary</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.summary"
                        placeholder="Enter summary..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>

                    <b-form-group  label-cols-lg="0">
                      <label>Image<small class="color-text-small">Width 500 x Height 500. Just only one.</small></label>
   
                      <vue-dropzone 
                        ref="myVueDropzone" 
                        id="form-image" 
                        accepted-files="image/*"
                        :options="dropzoneOptions"
                        :useCustomSlot="true" 
                        @vdropzone-success-multiple="vfileAdded" 
                      ></vue-dropzone>
                    </b-form-group>
            
                    <b-form-group label-cols-lg="0">
                      <label>Latitude</label>
                      <b-form-input
                        id="latitude"
                        v-model="form.latitude "
                        placeholder="Enter latitude "
                        name="latitude"
                        type="number"
                        step="any"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Longitude</label>
                      <b-form-input
                        id="longitude"
                        v-model="form.longitude "
                        placeholder="Enter longitude "
                        name="longitude"
                        type="number"
                        step="any"
                      ></b-form-input>                  
                    </b-form-group>
<!--                     <b-form-group label-cols-lg="0">
                      <label>Attraction place near</label>
                      <b-form-select v-model="selectedPlace" :options="optionsPlace" placeholder="Enter place"></b-form-select>
                    </b-form-group> -->
            <!--         <b-form-select
                     v-model="selected"
                     :options="options"
                     class="mb-3"
                     value-field="item"
                     text-field="name"
                     disabled-field="notEnabled"
                    ></b-form-select> -->
                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :on-cancel="onCancel"
                      :is-full-page="true"
                    ></loading>
    

   
                    <b-form-group>
                      <router-link to="/attraction">
                        <button
                          type="button"
                          class="btn btn-danger float-right ml-2"
                          >Cancel</button
                        >
                      </router-link>
                      <button type="submit" class="btn btn-primary float-right"
                        >Save</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scope>
.unactiveClass {
  border: 1px solid #5269f785;
  background: #5269f785;
}
</style>
